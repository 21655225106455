/* mobile menu */

// $('.header__hamburger').click(function(){
//     $(this).toggleClass('header__hamburger--open');
//     $('html, body').toggleClass('blocked');
//     $(".header__nav").toggleClass('header__nav--visible');
// });

/* mobile menu */

if ($(window).width() < 991) {
    $('.header__hamburger, .header__menuLink').click(function(){
        $('.header__hamburger').toggleClass('header__hamburger--open');
        $('html, body').toggleClass('blocked');
        $('body').toggleClass('menu-opened');
        $(".header__nav").toggleClass('header__nav--visible');
    });
}